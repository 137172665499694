@font-face {
  font-family: "Funktional Grotesk";
  font-style: normal;
  src: url("~/assets/fonts/funktional-grotesk/FunktionalGrotesk-Regular.eot");
  src: url("~/assets/fonts/funktional-grotesk/FunktionalGrotesk-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~/assets/fonts/funktional-grotesk/FunktionalGrotesk-Regular.woff2")
      format("woff2"),
    url("~/assets/fonts/funktional-grotesk/FunktionalGrotesk-Regular.woff")
      format("woff");
}
@font-face {
  font-family: 'Sabon';
  font-style: normal;
  src: url('~/assets/fonts/sabon-regular/af54786b-04a2-4a8e-bb84-1cba0bef5e4d.eot');
  src: url('~/assets/fonts/sabon-regular/af54786b-04a2-4a8e-bb84-1cba0bef5e4d.eot?#iefix') format('embedded-opentype'),
    url('~/assets/fonts/sabon-regular/aa680bb7-9623-4e76-80a4-73974085a966.woff2') format('woff2'),
    url('~/assets/fonts/sabon-regular/45f9d631-b3e2-4863-b55f-6402a4227430.woff') format('woff'),
    url('~/assets/fonts/sabon-regular/48a81c65-a8bb-4d31-a9c1-dba0620b2c50.ttf') format('truetype');
}

body {
  background: #000;
  margin: 0;
  color: #fff;
  font-family: "Funktional Grotesk", Georgia, Helvetica, Arial, sans-serif;
  cursor: url("~/assets/cursor.svg"), auto;

  &.inverted {
    cursor: url("~/assets/cursor-inv.svg"), auto;
  }
  &.nocursor {
    cursor: none;
  }
}
* {
  cursor: inherit;
}

#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.info-msg {
  @extend .fullsize;
  font-family: 'Sabon', serif;
  font-size: 2rem;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 101;
  transition: 6s opacity;

  a {
    color: inherit;
  }
  &.fade-out {
    pointer-events: none;
    opacity: 0;
  }

  @media screen and (max-width: 540px) {
    font-size: .9rem;
  }
}

.artwork {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background-position: center;
  background-size: cover;

  &--relative {
    top: auto;
    left: auto;
    position: relative;
  }
  &--white {
    background: #fff;
  }
}
.inactive {
  pointer-events: none;
}
.centercontent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fullsize {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.wide {
    width: 100%;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  &.high {
    width: auto;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.imagefill {
  object-fit: cover;
  object-position: center;
}
.bg-contain {
  background-color: #000;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.magnifier-wrap {
  background-color: #fff;
}
.magnifier {
  width: 10rem;
  height: 10rem;
  border-radius: 99%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s opacity, 0.5s width, 0.5s height;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 40px 5px rgba(#fff, 0.8);
    border-radius: 99%;
    z-index: 1;
  }
  .active & {
    opacity: 1;
    width: 22rem;
    height: 22rem;
  }
}
.magnifier-aw {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250vw;
  height: 250vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.expo-notice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  font-family: 'Sabon';
  font-size: 2rem;
  pointer-events: none;

  &.animated {
    animation: expofade 4s 1 linear forwards;
  }
}
@keyframes expofade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.expo-svg {
  mix-blend-mode: overlay;
  z-index: 1;

  path {
    // cursor: pointer;
    animation: 2.5s pulsate infinite ease-in-out;
    opacity: 0;
    filter: blur(1px);
    transition: 0.2s opacity;

    &:hover {
      opacity: 0.4;
      animation: none;
      animation-play-state: paused;
    }
    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 4s;
    }
    &:nth-child(3) {
      animation-delay: 2s;
    }
  }
}
@keyframes pulsate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}
.expo-video {
  @extend .fullsize;
}
.slide-canvas {
  background: #fff;
}
.slide-img {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;

  &.animated {
    animation: 30s slide 1 linear forwards;
  }
}
@keyframes slide {
  from {
    transform: translate3d(0, 0, 0);
    // left: 0;
  }
  to {
    transform: translate3d(-100%, 0, 0);
    // left: 100%;
  }
}
.zoom-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;

  &.animated {
    animation: 5s linear 0s infinite alternate zoomout;
  }
}
@keyframes zoomout {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}

.imagewall-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
  opacity: 0;
  transition: 2s opacity;

  &.visible {
    opacity: 1;
  }

  img {
    display: block;
    width: 80rem;
    height: auto;
  }
}

.neon-overlay {
  animation: 1s linear 0s infinite alternate neon-fade;
}

.neon-wrap {
  transform-style: preserve-3d;
  transform: perspective(700px);
  z-index: 10;
}

.neon-element {
  display: block;
  position: absolute;
  // border: 1px solid red;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  opacity: 0;
  backface-visibility: hidden;
  z-index: 10;
  transition: 2s transform cubic-bezier(0.19, 1, 0.22, 1), 1s opacity;

  &--1 {
    transform: translate(-52%, -111%) scale(0.15);
  }
  &--2 {
    transform: translate(-4%, -90%) scale(0.15);
  }
  &--3 {
    transform: translate(10%, -115%) scale(0.15);
  }
  &--4 {
    transform: translate(35%, -110%) scale(0.15);
  }
  &.active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  &.nopointer {
    pointer-events: none;
  }
}

@keyframes neon-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.round-video {
  border-radius: 100%;
  overflow: hidden;
  width: 30%;
  height: auto;
  position: absolute;
}

.memory-wrap {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.memory-grid {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  img {
    // grid-column: span 1;
    width: auto;
    height: auto;
    opacity: 0;
    transition: 0.5s opacity;

    &.visible {
      opacity: 1;
    }
  }
}

.chair {
  background-color: #fff;
  color: #000;

  &__chairimg {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  &__chair {
    height: 80vh;
    width: auto;
    transform-origin: 50% 100%;
    opacity: 0;
    overflow: visible;
    transition: 1s opacity;

    &.visible {
      opacity: 1;
    }
  }
  &__textwrap {
    z-index: 1;

    span {
      font-size: 2vw;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      line-height: 1.12;
      pointer-events: none;
      user-select: none;
      transition: 1s opacity;

      &.visible {
        opacity: 1;
      }

      &:nth-child(1) {
        top: 5vh;
        left: 20vw;
      }
      &:nth-child(2) {
        top: 10vh;
        left: 60vw;
      }
      &:nth-child(3) {
        top: 15vh;
        left: 40vw;
        width: 20vw;
      }
      &:nth-child(4) {
        top: 30vh;
        left: 10vw;
        width: 32vw;
      }
      &:nth-child(5) {
        top: 40vh;
        left: 44vw;
      }
      &:nth-child(6) {
        top: 50vh;
        left: 50vw;
      }
      &:nth-child(7) {
        top: 70vh;
        left: 5vw;
      }
      &:nth-child(8) {
        top: 80vh;
        left: 50vw;
      }

      @media screen and (max-width: 1400px) {
        font-size: 2.5vw;
      }
      
    }
  }
  &__installation {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    object-fit: contain;
    opacity: 0;
    transition: 4s opacity;

    &.visible {
      opacity: 1;
    }
  }
}

.words-img,
.words-curtain {
  background-color: #fff;
}
.words-img {
  background-size: 80% auto;
  background-repeat: no-repeat;
  background-position: center;
}
.words-curtain {
  transform: scaleY(0);
  transform-origin: 100% 0;
  z-index: 1;
  background-color: #fff;
  height: 8vw;
  top: 50%;
}
.words-svg {
  z-index: 2;
}

.spotlight-image {
  object-fit: cover;
}
.spotlight-bg {
  background-size: cover;
  background-position: center;
}

.audioimage-image {
  object-fit: cover;
  object-position: center;
}

.controls {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 999;
  width: 30rem;

  &__toggle {
    appearance: none;
    background: rgba(#fff, 0.5);
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    border: none;

    &:hover {
      background-color: #000;
    }
  }
  &__list {
    flex-wrap: wrap;
    display: none;
    width: 30rem;
    margin-left: -4px;
    margin-top: 4px;

    button {
      display: block;
      appearance: none;
      border: 1px solid black;
      margin: 4px;
      background: #fff;
      font-family: "Helvetica Neue", sans-serif;
      padding: 0.5rem 1rem;
      width: calc(33.3% - 8px);
      cursor: pointer;
    }
    &--visible {
      display: flex;
    }
  }
}

.audio-button {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  appearance: none;
  border: none;
  background: none;
  background-image: url("~/assets/icons/sound-on.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 21;

  .inverted & {
    background-image: url("~/assets/icons/sound-on-inv.svg");
  }
  &.muted {
    background-image: url("~/assets/icons/sound-off.svg");

    .inverted & {
      background-image: url("~/assets/icons/sound-off-inv.svg");
    }
  }
}

.proceed-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.arrow {
  position: fixed;
  top: 0;
  right: 0;
  width: 10rem;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  background-image: url('~/assets/icons/arrow.svg');
  background-size: 5rem auto;
  background-repeat: no-repeat;
  background-position: center;
  animation: 2s showArrow 0s 1 linear;

  .inverted & {
    background-image: url('~/assets/icons/arrow-inv.svg');
  }
}

@keyframes showArrow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.video-loading {
  font-size: 2rem;
  text-align: center;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Sabon', serif;
}

.destroyed {
  pointer-events: none;
}